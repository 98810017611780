.footer
  font-family: "ProximaNova", "Arial", sans-serif
  color: $white
  background-color: #1F2229

.footer__wrapper
  display: flex
  padding: 46px 0 60px 0
  line-height: 16px

.footer__copyright
  margin: 0
  font-size: 13px

.footer__copyright-link
  display: flex
  flex-direction: column
  display: inline
  color: $white
  text-decoration: underline

.footer__policy
  display: block
  margin-top: 20px
  text-decoration: underline
  color: $white
  
.footer__callback
  margin: 0
  margin-left: 167px
  font-size: 13px

.footer__phone
  display: block
  margin-top: 10px
  font-size: 18px
  font-weight: 700
  line-height: 22px
  color:  $white

.footer__logo-link
  display: flex
  margin-left: auto

.footer__logo
  width: 175px
  height: 25px

// @media (max-width: $mobile-width)
//   .footer
//     margin-top: 43px

//   .footer__wrapper
//     flex-direction: column
//     align-items: flex-start
//     padding: 42px 0 51px

//   .footer__copyright-link
//     margin: 0
//     font-size: 13px

//     span
//       display: block

//   .footer__copyright
//     margin: 5px 0

//   .footer__policy
//     margin-top: 10px
//     text-decoration: underline
//     color: $white
    
//   .footer__callback
//     margin: 40px 0 0 0
//     font-size: 13px

//   .footer__phone
//     position: relative
  
//     &::before
//       content: ''
//       position: absolute
//       top: 0
//       left: 0
//       width: 48px
//       height: 48px
//       background: url('../img/icon/icon-phone.svg') no-repeat center

//   .footer__logo-link
//     margin: 40px 0 0 0

//   .footer__logo
//     width: 140px