.promo
  position: relative
  z-index: 0

.promo__content
  position: relative
  padding: 70px 0 56px 80px
  background-color: #FAF8F5
  border-radius: 20px

.promo__title
  width: 490px
  font-size: 30px
  font-weight: 600
  line-height: 35px

.promo__desc
  width: 430px
  margin-top: 26px
  font-size: 16px
  line-height: 22px

.promo__link
  width: 402px
  height: 60px
  margin-top: 26px
  font-size: 20px
  font-weight: 600
  border-radius: 12px

.promo__pic-wrapper
  position: absolute
  top: 32px
  right: 37px
  width: 610px
  height: 360px
  object-fit: contain

.promo__content-pic
  position: absolute
  height: 100%
  object-fit: contain
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))

.tutortop-rating,
.vtb-cashback
  position: absolute
  top: 46px
  right: 10px
  width: 106px
  height: 106px
  z-index: 2

.vtb-cashback
  top: 160px
  right: 3px
  width: 112px
  height: 115px

.tutortop-rating__pic,
.vtb-cashback__pic
  width: 100%
  height: 100%

