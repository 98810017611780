.attestation
  margin-top: 52px

.attestation__content
  position: relative
  padding: 31px 0 33px 80px
  background-color: #FAF8F5
  border-radius: 20px

.attestation__bage
  position: relative
  display: flex
  justify-content: center
  align-items: center
  height: 16px
  width: max-content
  padding: 0 7px 0 24px
  font-size: 10px
  font-weight: 600
  color: white
  background-color: #F6BA2F
  border-radius: 4px

  &::before
    content: ''
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 7px
    width: 13px
    height: 13px
    background: url('../img/icons/icon-attestation.svg') no-repeat center

.attestation__title
  width: 415px
  margin-top: 20px
  font-size: 30px
  font-weight: 600
  line-height: 34px

.attestation__lnik
  width: 300px
  height: 51px
  margin-top: 30px
  font-size: 16px
  font-weight: 600

.attestation__pic
  position: absolute
  top: 23px
  right: 119px
  width: 393px
  height: 206px
