.study
  padding-top: 100px

.study__title
  font-size: 30px
  font-weight: 600
  text-align: center

.study__wrapper
  margin: 0 -16px
  // overflow: hidden

.study__options
  margin-top: 30px

.options-table
  border-spacing: 16px 0
  
  tr
    &:not(:first-of-type)
      td
        position: relative
        

    &:first-of-type
      td
        padding: 10px 0

        span
          display: flex
          justify-content: center
          align-items: center
          width: auto
          height: 70px
          margin: 0 10px
          padding: 15px 5px
          background-color: $basic-orange
          border-radius: 5px
          color: $white

        &::before
          display: none

        &:not(:first-of-type)
          border-radius: 10px 10px 0 0
      
    &:last-of-type
      td
        &::before
          display: none

  td
    width: 173px 
    padding: 25px 0

    &::before
      content: ''
      position: absolute
      bottom: 0
      height: 1px
      background-color: #E2E2E2
      width: 100%

    &:not(:first-of-type)
      padding-left: 10px
      padding-right: 10px

      &::before
        left: 50%
        transform: translateX(-50%)
        width: 85%

    &:first-of-type
      width: 246px

    &:nth-child(2)
      width: 160px

    &:not(:first-of-type)
      text-align: center
      background-color: #FAF8F5

.options-table__data
  font-size: 16px
  font-weight: 600
  line-height: 20px

.options-table__link
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 34px
  margin-top: 10px
  padding: 0 20px
  font-size: 10px
  line-height: 12px
  border-radius: 5px
  color: $white

.study__link
  width: 300px
  height: 51px
  margin: 50px auto 0