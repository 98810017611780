/* Fonts */
@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 100
  src: local("ProximaNovaT-Thin"), local("Proxima NovaT Thin"), url("../fonts/ProximaNovaT-Thin.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 300
  src: local("ProximaNova-Light"), local("Proxima Nova Light"), url("../fonts/ProximaNova-Light.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 400
  src: local("ProximaNova-Regular"), local("Proxima Nova Regular"), url("../fonts/ProximaNova-Regular.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 600
  src: local("ProximaNova-Semibold"), local("Proxima Nova Semibold"), url("../fonts/ProximaNova-Semibold.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 700
  src: local("ProximaNova-Bold"), local("Proxima Nova Bold"), url("../fonts/ProximaNova-Bold.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 800
  src: local("ProximaNova-Extrabld"), local("Proxima Nova Extrabld"), url("../fonts/ProximaNova-Extrabld.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 900
  src: local("ProximaNova-Black"), local("Proxima Nova Black"), url("../fonts/ProximaNova-Black.woff2") format("woff2")
/*  */