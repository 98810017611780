.support
  margin-top: 144px

.support__wrapper
  position: relative
  padding: 84px 0 42px 91px
  color: $white
  background-color: #27A6E5
  border-radius: 20px
  z-index: 0

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 280px
    background: url('../img/icons/icon-tg-bg.svg') no-repeat center
    z-index: -1

.support__title
  width: 550px
  font-size: 30px
  line-height: 35px
  font-weight: 600

.support__desc
  width: 550px
  margin-top: 20px
  font-size: 16px
  line-height: 22px

.support__link
  width: 340px
  height: 50px
  margin: 30px 0 0 0
  font-size: 16px
  background-color: #ffffff
  color: $black

.support__pic
  position: absolute
  right: 0
  bottom: 0
  width: 480px

// @media ( max-width:  $mobile)
//   .support
//     margin-top: 73px

//   .support__wrapper
//     position: relative
//     padding: 35px 25px 333px
//     color: $white
//     background-color: $basic-blue
//     border-radius: 20px

//     &::before
//       content: ''
//       position: absolute
//       top: 0
//       right: auto
//       left: 50%
//       transform: translateX(-50%)
//       width: 80%
//       height: 100%
//       background: url('../img/icon/icon-tg-bg.svg') no-repeat center left
//       object-fit: cover

//   .support__title
//     width: auto
//     font-size: 30px
//     line-height: 36px

//     span
//       display: inline

//   .support__link
//     width: 100%
    
//   .support__pic
//     width: 240px
//     height: auto
//     position: absolute
//     right: 0
//     left: 50%
//     bottom: 0
//     transform: translateX(-50%)

    