.questions
  margin-top: 94px
  background-color: $white

.questions__title
  margin: 0
  font-size: 40px
  font-weight: 600
  text-align: center

.questions__lists
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 20px
  margin-top: 40px


.questions__lists-elem
  &:first-of-type
    .question__item
        &:first-of-type
          background-color: #FAF8F5
  
.questions__list
  display: flex
  flex-direction: column
  gap: 20px
// .question

.question__item
  display: flex
  flex-direction: column
  width: 580px
  padding: 40px
  border: 1px solid #D8D8D8
  border-radius: 10px

.question__button
  position: relative
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 0
  font-size: 28px
  font-weight: 600
  text-align: left
  color: #2D3039
  border: 0
  background-color: transparent
  cursor: pointer
  user-select: none

  &:before 
    content: ''
    position: absolute
    top: 16px
    transform: translateY(-50%)
    right: 0
    width: 25px
    height: 25px
    background: url('../img/icons/icon-plus.svg') no-repeat center
    // transform: rotate(180deg)
    background-size: contain
    transition: 0.1s 

  &--active 
    padding-bottom: 0
    + .question__answer
        display: block 

    &:before 
      // transform: rotate(180deg)   
      background: url('../img/icons/icon-minus.svg') no-repeat center

.question__answer
  width: 100%
  margin-top: 15px
  font-size: 20px
  line-height: 30px
  animation: transition .2s linear
  display: none
  user-select: none

@keyframes transition 
  0% 
    opacity: 0
    transform: translateY(-20px) 
  100% 
    opacity: 1
    transform: translateY(0) 



// @media (max-width: $mobile-width)
//   .questions
//     margin-top: 43px
//     padding: 0 0 25px

//   .questions__title
//     font-size: 30px
//     text-align: left
    
//   .questions__list
//     margin-top: 16px

//   // .question

//   .question__item
//     display: flex
//     flex-direction: column
//     padding: 16px 0 19px
//     border-bottom: 1px solid #D8D8D8

//     &:last-of-type
//       border-bottom: none

//   .question__button
//     margin-top: 10px
//     padding: 0
//     padding-right: 25px
//     font-size: 18px
//     text-align: left
    
//     &:before 
//       top: 7px
//       width: 15px
//       height: 12px

//   .question__answer
//     width: auto
//     margin-top: 7px
//     font-size: 14px
//     line-height: 19px