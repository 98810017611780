.education
  padding-top: 82px

.education__title
  width: 850px
  margin: 0 auto
  font-size: 30px
  font-weight: 600
  line-height: 34px
  text-align: center

.education__directions-choice
  margin-top: 50px

.direction
  display: flex
  flex-wrap: wrap
  gap: 16px

.direction__elem
  display: inline-block
  width: max-content

.direction__btn
  display: flex
  justify-content: center 
  align-items: center
  width: 100%
  height: 32px
  padding: 0 25px
  font-size: 16px
  font-weight: 600
  color: $basic-orange
  border: 1px solid $basic-orange
  border-radius: 6px
  cursor: pointer

.direction-selected
  background-color: $basic-orange
  color: $white

.education__training-list
  display: grid
  grid-template-columns: repeat(3, 380px)
  grid-gap: 20px
  margin-top: 40px

// 
.training
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 20px
  background-color: #FAF8F5
  border-radius: 20px
  user-select: none
  display: none
  
.training__params-list
  display: flex
  width: max-content
  justify-content: space-between
  gap: 5px

.training--show
  display: flex

.training__params-elem
  position: relative
  display: flex
  align-items: center
  height: 19px
  padding-left: 18px
  font-size: 10px
  color: #A4A4A4

  &::before
    content: ''
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    width: 15px
    height: 15px
  
  &:first-of-type
    &::before
      background: url('../img/icons/icon-calendar.svg') no-repeat center
  
  &:nth-child(2)
    &::before
      background: url('../img/icons/icon-certificate.svg') no-repeat center
  
  &:nth-child(3)
    &::before
      background: url('../img/icons/icon-clock.svg') no-repeat center
  
  &:nth-child(4)
    &::before
      background: url('../img/icons/icon-course.svg') no-repeat center
      
.training__title
  margin-top: 22px
  font-size: 20px
  font-weight: 600
  line-height: 24px
  
.training__desc
  font-size: 14px
  line-height: 18px
  margin-top: 20px

// 
.training__subject-btn
  margin-top: 15px
  margin-bottom: 20px
  font-size: 14px
  color: #A4A4A4
  text-decoration: underline
  text-decoration-thickness: 1px
  text-underline-offset: 4px
  cursor: pointer
  transition: 0.3s

  &:hover
    color: #cabfbf

.training__subject-list
  margin-top: 15px
  display: none

.training__subject
  position: relative
  padding-left: 22px
  font-size: 16px
  line-height: 130%
  color: #8892B2

  &::before
    content: ''
    position: absolute
    top: 3px
    left: 0
    width: 12px
    height: 12px
    background: url('../img/icons/icon-bullit.svg') no-repeat center 
    background-size: cover

.training__prices
  margin-top: auto
  order: 1

.training__prices-new
  font-size: 20px 
  font-weight: 600

.training__prices-old
  margin-left: 15px
  font-size: 14px
  color: #A4A4A4
  text-decoration: line-through

.training__pay-wrapper
  display: flex
  flex-direction: column
  gap: 10px
  width: 100%
  margin-top: 20px
  order: 1

.training__pay-card,
.training__pay-bill
  display: flex
  height: 41px
  font-size: 14px
  font-weight: 600
  border-radius: 5px


.training__pay-bill
  color: $basic-orange
  border: 1px solid $basic-orange