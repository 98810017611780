.about
  padding-top: 133px

.about__wrapper
  position: relative
  padding: 60px 0 59px 50px
  background-color: #FAF8F5
  border-radius: 20px

.about__title
  font-size: 40px
  line-height: 35px
  font-weight: 600

.about__desc
  width: 570px
  margin-top: 37px
  font-size: 18px
  line-height: 25px

.about__link
  width: 340px
  height: 50px
  margin: 30px 0 0 0
  font-size: 16px
  background-color: #ffffff
  color: $black

// .about__pic
//   position: absolute
//   right: 106px
//   bottom: 50px
//   width: 337px
//   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))

/*  */
.diplom
  position: absolute
  right: 80px
  top: -90px

.diplom-back 
  position: relative
  perspective: 3000px
  perspective-origin: 50% 70%

.diplom-back img
  max-width: 375px

.diplom-back__prof-pic 
  position: absolute
  right: 60px
  bottom: 150px
  display: none

.diplom-back__prof-pic img 
  max-width: 150px

.diplom-back__prof-pic.prof-pic__anim 
  animation: 1.4s linear picdow
  display: block

@keyframes picdow 
  0% 
    opacity: 0
    transform: translateY(-2000px)

  60% 
    opacity: 1
    transform: translateY(30px)

  80% 
    transform: translateY(-10px)

  100% 
    transform: translateY(0)
	
.diplom-back__data 
  position: absolute
  display: inline-block
  right: 50px
  top: 120px
  z-index: 4
  font-size: 12px
  max-width: 150px
  font-weight: bold

.diplom-back__data span
  display: block

.diplom-back__name 
  margin-bottom: 15px

.diplom-back__program
  margin-bottom: 70px
  font-size: 12px
  line-height: 1

.diplom-back__course,
.diplom-back__date
  font-size: 12px
  line-height: 1

.diplom-front 
  position: absolute
  top: 0
  left: 0
  transform-origin: left center
  transform: rotateY(0deg)
  transition: .5s
  z-index: 5

.diplom-front:hover 
  transform: rotateY(-25deg)
  transition: .5s
  cursor: pointer

.diplom-front.animate 
  transform: rotateY(-84deg)

