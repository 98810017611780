
// .header

.header-top
  display: flex
  align-items: center
  height: 34px
  padding-left: 18px
  line-height: 0
  border-bottom: 1px solid #E5E5E5

.header__logo
  padding-left: 18px

.header__access-link
  margin-left: auto
  font-size: 12px
  line-height: normal
  color: #5D5A59

.header__phone
  margin: 0 30px
  padding-left: 17px

  &::before
    content: ''
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    width: 10px
    height: 10px
    background: url('../img/icons/icon-phone.svg') no-repeat center

.header__signin
  // line-height: 0
  display: inline-block
  align-self: flex-end

.header-bottom
  padding: 21px 0 27px

.header-bottom__content
  display: flex
  align-items: center
  height: 37px
  line-height: 0

.header-bottom__nav
  margin-left: auto

.navigation__list
  width: 780px
  display: flex
  justify-content: space-between

.navigation__link
  font-size: 14px
  font-weight: 600
  line-height: 25px
  color: $black

.header-bottom__phone-wrapper
  position: relative
  margin-left: 57px
  padding-left: 38px
  font-size: 14px
  line-height: normal

  span
    display: block
    font-size: 12px

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 25px
    height: 25px
    background: url('../img/icons/icon-whatsapp.svg') no-repeat center

.header-bottom__phone
  font-size: 14px
  font-weight: 600
  color: $black